import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path fill="#F35325" d="M0,0h10v10H0V0z" />
    <path fill="#81BC06" d="M11,0h10v10H11V0z" />
    <path fill="#05A6F0" d="M0,11h10v10H0V11z" />
    <path fill="#FFBA08" d="M11,11h10v10H11V11z" />
  </svg>
))
