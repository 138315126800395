import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 500 228.7"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <g>
      <path
        fill="#FF4A00"
        d="M0,180l51.8-80.5H6.1c-0.4-2.3-0.6-4.9-0.6-7.7c0-2.7,0.2-5.2,0.6-7.5h73.8l1,2.5l-52.1,80.7h48.9
		c0.4,2.6,0.6,5.2,0.6,7.9c0,2.6-0.2,5-0.6,7.3H1L0,180z"
      />
      <path
        fill="#FF4A00"
        d="M149.3,117.9c0-7.5-1.9-12.8-5.8-15.7c-3.8-2.9-9.4-4.4-16.7-4.4c-4.5,0-8.7,0.4-12.6,1.1
		c-3.9,0.7-7.7,1.6-11.4,2.8c-2.4-4.2-3.6-9.3-3.6-15.1c4.3-1.4,9.1-2.5,14.4-3.3c5.2-0.8,10.3-1.2,15.1-1.2
		c12.8,0,22.5,2.9,29.1,8.7c6.6,5.8,10,15.1,10,27.9v61.5c-4.5,1-9.9,2.1-16.3,3.2c-6.4,1.1-12.9,1.6-19.6,1.6
		c-6.3,0-11.9-0.6-17-1.7c-5-1.2-9.3-3-12.8-5.6c-3.5-2.6-6.2-5.8-8.1-9.8c-1.9-4-2.9-8.8-2.9-14.4c0-5.5,1.1-10.3,3.4-14.5
		c2.2-4.1,5.3-7.6,9.1-10.4c3.8-2.7,8.2-4.8,13.2-6.1c5-1.3,10.2-2,15.7-2c4.1,0,7.4,0.1,10.1,0.3c2.6,0.2,4.8,0.4,6.6,0.7V117.9z
		 M149.3,136.5c-1.9-0.3-4.3-0.5-7.3-0.8c-2.9-0.3-5.4-0.4-7.5-0.4c-7.9,0-14,1.5-18.1,4.4c-4.2,2.9-6.2,7.4-6.2,13.4
		c0,3.8,0.7,6.8,2.1,9c1.4,2.2,3.2,3.8,5.3,5c2.1,1.1,4.4,1.9,7,2.1c2.6,0.3,5,0.4,7.3,0.4c2.9,0,6-0.2,9.1-0.5
		c3.1-0.3,5.9-0.8,8.3-1.4V136.5z"
      />
      <path
        fill="#FF4A00"
        d="M188.6,84.4c1.3-0.3,2.6-0.4,3.9-0.6c1.3-0.1,2.7-0.2,4.1-0.2c1.3,0,2.6,0.1,4,0.2c1.4,0.1,2.7,0.3,4,0.6
		c0.1,0.3,0.3,1.1,0.5,2.4c0.2,1.3,0.4,2.8,0.6,4.3c0.2,1.5,0.4,3,0.6,4.4c0.2,1.4,0.3,2.3,0.3,2.7c1.3-2,2.8-4,4.6-5.9
		c1.8-1.9,3.9-3.6,6.4-5.2c2.5-1.5,5.3-2.7,8.4-3.6c3.1-0.9,6.6-1.3,10.4-1.3c5.7,0,11.1,1,16,2.9c4.9,1.9,9.1,4.9,12.7,8.9
		c3.5,4,6.3,9.1,8.2,15.3c2,6.2,3,13.5,3,22c0,16.9-4.6,30.1-13.7,39.6c-9.1,9.5-22.1,14.3-38.8,14.3c-2.8,0-5.7-0.2-8.6-0.6
		c-2.9-0.4-5.5-0.9-7.7-1.5V228c-1.5,0.3-3.2,0.4-4.9,0.6c-1.7,0.1-3.3,0.2-4.7,0.2c-1.4,0-3-0.1-4.7-0.2c-1.7-0.1-3.3-0.3-4.7-0.6
		V84.4z M207.6,166.6c2.4,0.9,4.9,1.5,7.5,1.9c2.6,0.4,5.9,0.6,10,0.6c4.6,0,8.8-0.7,12.7-2.2c3.8-1.5,7.1-3.7,9.9-6.8
		c2.7-3.1,4.9-6.9,6.5-11.6c1.6-4.7,2.4-10.2,2.4-16.6c0-10.2-1.9-18.3-5.7-24.3c-3.8-6-9.9-9-18.5-9c-3.2,0-6.3,0.6-9.2,1.7
		c-2.9,1.1-5.6,2.9-7.9,5.2c-2.3,2.3-4.2,5.2-5.6,8.7c-1.4,3.5-2.1,7.7-2.1,12.6V166.6z"
      />
      <path
        fill="#FF4A00"
        d="M300,99.3h-12.5c-0.3-1-0.4-2.2-0.6-3.5c-0.1-1.3-0.2-2.7-0.2-3.9c0-1.3,0.1-2.6,0.2-3.9
		c0.1-1.3,0.3-2.5,0.6-3.5h31.1v98.4c-1.4,0.3-3,0.4-4.7,0.6c-1.7,0.1-3.3,0.2-4.7,0.2c-1.3,0-2.8-0.1-4.5-0.2
		c-1.7-0.1-3.3-0.3-4.7-0.6V99.3z"
      />
      <path
        fill="#FF4A00"
        d="M359.5,138.2c0.3,10.7,3,18.6,8.3,23.6c5.3,5,13.1,7.5,23.5,7.5c9.1,0,17.7-1.6,25.9-4.8c1,1.9,1.9,4.2,2.5,7
		c0.6,2.8,1,5.5,1.2,8.1c-4.2,1.8-8.9,3.1-13.9,4c-5.1,0.9-10.6,1.3-16.8,1.3c-8.9,0-16.6-1.2-23-3.7c-6.4-2.5-11.7-6-15.8-10.5
		c-4.2-4.5-7.2-9.9-9.1-16.1c-1.9-6.2-2.9-13-2.9-20.4c0-7.3,0.9-14.1,2.8-20.4c1.9-6.3,4.7-11.8,8.5-16.5
		c3.8-4.7,8.7-8.4,14.5-11.1c5.8-2.7,12.7-4.1,20.6-4.1c6.8,0,12.7,1.2,17.8,3.5c5.1,2.3,9.4,5.5,12.8,9.5c3.4,4,6.1,8.8,7.9,14.4
		c1.8,5.6,2.7,11.6,2.7,18.1c0,1.8-0.1,3.7-0.2,5.7c-0.1,2-0.3,3.7-0.4,5.1H359.5z M408.2,124c0-3.6-0.5-7-1.4-10.3
		c-1-3.3-2.4-6.1-4.2-8.5c-1.9-2.4-4.2-4.4-7.1-5.8c-2.9-1.5-6.3-2.2-10.3-2.2c-7.8,0-13.8,2.4-17.9,7.1c-4.2,4.7-6.7,11.3-7.6,19.7
		H408.2z"
      />
      <path
        fill="#FF4A00"
        d="M447.9,84.4c1.4-0.3,2.7-0.4,4-0.6c1.3-0.1,2.6-0.2,4-0.2c1.4,0,2.8,0.1,4.2,0.2c1.4,0.1,2.7,0.3,3.8,0.6
		c0.4,1.9,0.8,4.4,1.1,7.6c0.4,3.1,0.6,5.8,0.6,8c2.7-4.3,6.2-8.1,10.6-11.3c4.4-3.2,10.1-4.8,17-4.8c1,0,2.1,0,3.2,0.1
		c1.1,0.1,2,0.2,2.8,0.3c0.3,1.2,0.4,2.4,0.6,3.6c0.1,1.3,0.2,2.6,0.2,4c0,1.5-0.1,3.1-0.3,4.8c-0.2,1.7-0.4,3.3-0.7,4.8
		c-1-0.3-2.1-0.4-3.2-0.4c-1.1,0-1.9,0-2.6,0c-3.4,0-6.7,0.5-9.9,1.4c-3.1,1-5.9,2.7-8.4,5.1c-2.5,2.4-4.5,5.8-5.9,10
		c-1.5,4.2-2.2,9.6-2.2,16.3v48.9c-1.4,0.3-3,0.4-4.7,0.6c-1.7,0.1-3.3,0.2-4.7,0.2c-1.4,0-3-0.1-4.7-0.2c-1.7-0.1-3.4-0.3-4.9-0.6
		V84.4z"
      />
      <path
        fill="#FF4A00"
        d="M336.7,26.5h-18.6l13.1-13.1c-1-1.5-2.2-2.8-3.4-4.1v0c-1.3-1.3-2.6-2.4-4.1-3.4L310.6,19V0.4
		c-1.7-0.3-3.5-0.4-5.3-0.4h0c-1.8,0-3.6,0.2-5.3,0.4V19L286.9,5.9c-1.5,1-2.8,2.2-4.1,3.4l0,0c-1.3,1.3-2.4,2.6-3.4,4.1l13.1,13.1
		H274c0,0-0.4,3.5-0.4,5.3v0c0,1.8,0.2,3.6,0.4,5.3h18.6l-13.1,13.1c2.1,2.9,4.6,5.4,7.5,7.5L300,44.6v18.6c1.7,0.3,3.5,0.4,5.3,0.4
		h0c1.8,0,3.6-0.2,5.3-0.4V44.6l13.1,13.1c1.4-1,2.8-2.2,4.1-3.4l0,0c1.3-1.3,2.4-2.6,3.4-4.1l-13.1-13.1h18.6
		c0.3-1.7,0.4-3.5,0.4-5.3v0C337.2,30,337,28.2,336.7,26.5z M313.3,31.8c0,2.4-0.4,4.6-1.2,6.7c-2.1,0.8-4.4,1.2-6.7,1.2h0
		c-2.4,0-4.6-0.4-6.7-1.2c-0.8-2.1-1.2-4.4-1.2-6.7v0c0-2.4,0.4-4.6,1.2-6.7c2.1-0.8,4.4-1.2,6.7-1.2h0c2.4,0,4.6,0.4,6.7,1.2
		C312.9,27.2,313.3,29.4,313.3,31.8L313.3,31.8z"
      />
    </g>
  </svg>
))
