import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 122.8 122.8" xmlns="http://www.w3.org/2000/svg" {...props}>
    {children}
    <g>
      <g>
        <path
          fill="#E01E5A"
          d="M25.8,77.6c0,7.1-5.8,12.9-12.9,12.9S0,84.7,0,77.6c0-7.1,5.8-12.9,12.9-12.9h12.9V77.6z"
        />
        <path
          fill="#E01E5A"
          d="M32.3,77.6c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9
			C32.3,109.9,32.3,77.6,32.3,77.6z"
        />
      </g>
      <g>
        <path
          fill="#36C5F0"
          d="M45.2,25.8c-7.1,0-12.9-5.8-12.9-12.9S38.1,0,45.2,0s12.9,5.8,12.9,12.9v12.9H45.2z"
        />
        <path
          fill="#36C5F0"
          d="M45.2,32.3c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H12.9C5.8,58.1,0,52.3,0,45.2s5.8-12.9,12.9-12.9
			C12.9,32.3,45.2,32.3,45.2,32.3z"
        />
      </g>
      <g>
        <path
          fill="#2EB67D"
          d="M97,45.2c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H97V45.2z"
        />
        <path
          fill="#2EB67D"
          d="M90.5,45.2c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V12.9C64.7,5.8,70.5,0,77.6,0
			c7.1,0,12.9,5.8,12.9,12.9V45.2z"
        />
      </g>
      <g>
        <path
          fill="#ECB22E"
          d="M77.6,97c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V97H77.6z"
        />
        <path
          fill="#ECB22E"
          d="M77.6,90.5c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9
			c0,7.1-5.8,12.9-12.9,12.9H77.6z"
        />
      </g>
    </g>
  </svg>
))
