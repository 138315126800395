import React, { FC } from "react"
import { Props, IconProps } from "./types"
import * as styles from "./styles.module.scss"
import ArrowLink from "src/components/ArrowLink"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"
import Slack from "src/components/Icons/Slack"
import Dropbox from "src/components/Icons/Dropbox"
import GoogleDrive from "src/components/Icons/GoogleDrive"
import OneDrive from "src/components/Icons/OneDrive"
import Box from "src/components/Icons/BoxLogo"
import Google from "src/components/Icons/GoogleLogo"
import Microsoft from "src/components/Icons/MicrosoftColor"
import Sharepoint from "src/images/sharepoint.png"
import Harvest from "src/images/harvest.png"

const Icon: FC<IconProps> = ({ icon }) => {
  // noinspection JSRedundantSwitchStatement
  switch (icon) {
    case "Slack":
      return <Slack className={`${styles.logo}`} aria-hidden="true" />
    case "Dropbox":
      return <Dropbox className={`${styles.logo}`} aria-hidden="true" />
    case "Google Drive":
      return <GoogleDrive className={`${styles.logo} `} aria-hidden="true" />
    case "OneDrive":
      return (
        <OneDrive
          className={`${styles.logo} ${styles.oneDrive}`}
          aria-hidden="true"
        />
      )
    case "Box":
      return (
        <Box className={`${styles.logo} ${styles.box}`} aria-hidden="true" />
      )
    case "Google SSO":
      return <Google className={`${styles.logo}`} aria-hidden="true" />
    case "Microsoft SSO":
      return <Microsoft className={`${styles.logo}`} aria-hidden="true" />
    case "Sharepoint":
      return (
        <img
          src={Sharepoint}
          className={`${styles.logo} ${styles.sharepoint}`}
        />
      )
    default:
      return <img src={Harvest} className={styles.logo} />
  }
}

const IntegrationCard: FC<Props> = ({
  description,
  name,
  url,
  linkText,
  className,
}) => {
  const { getPage } = usePages()
  return (
    <Link to={getPage(url).url} className={`${styles.card} ${className}`}>
      <div className={styles.header}>
        <Icon icon={name} />
      </div>
      <div className={styles.body}>
        <h2 className={styles.title}>{name}</h2>
        <p className={styles.description}>{description}</p>
        <ArrowLink
          as="span"
          text={linkText}
          to={getPage(url).url}
          className={styles.link}
        />
      </div>
    </Link>
  )
}

export default IntegrationCard
