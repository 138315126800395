import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 421.1 366.3" xmlns="http://www.w3.org/2000/svg" {...props}>
    {children}
    <g id="logo_drive_96in128dp" transform="translate(11.564472, 2.891791)">
      <g id="Group_7_" transform="translate(9.155207, 36.629353)">
        <g id="Clipped">
          <g>
            <defs>
              <filter
                id="Adobe_OpacityMaskFilter"
                filterUnits="userSpaceOnUse"
                x="46.3"
                y="207.7"
                width="354.1"
                height="119"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="46.3"
              y="207.7"
              width="354.1"
              height="119"
              id="mask-2_2_"
            >
              <g filter="url(#Adobe_OpacityMaskFilter)">
                <path
                  id="path-1_2_"
                  fill="#ffffff"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M258.6-39.5H121.1L-20.7,207.7l67.1,119h287l67.1-119L258.6-39.5z M115.3,207.7
							l74.5-128.7l74.5,128.7H115.3z"
                />
              </g>
            </mask>
            <g id="Group_1_" mask="url(#mask-2_2_)">
              <g transform="translate(67.018979, 247.248134)">
                <polygon
                  id="Shape"
                  fill="#4285F4"
                  points="-20.7,-39.5 -20.7,79.5 266.3,79.5 333.4,-39.5 						"
                />

                <linearGradient
                  id="Shape_10_"
                  gradientUnits="userSpaceOnUse"
                  x1="861.7123"
                  y1="300.8384"
                  x2="862.4659"
                  y2="300.3645"
                  gradientTransform="matrix(137.9461 0 0 -119.0454 -118855.6016 35833.6602)"
                >
                  <stop offset="0" stopColor="#1A237E" stopOpacity="0.2" />
                  <stop
                    offset="1"
                    stopColor="#1A237E"
                    stopOpacity="2.000000e-02"
                  />
                </linearGradient>
                <polygon
                  id="Shape_1_"
                  fill="url(#Shape_10_)"
                  points="48.3,-39.5 117.3,79.5 -20.7,79.5 						"
                />
                <g id="Group">
                  <polygon
                    id="Shape_2_"
                    fill="#ffffff"
                    fillOpacity="0.2"
                    points="47,-37.2 329.5,-37.2 329.5,-37.2 333.4,-39.5 48.3,-39.5 48.3,-39.5 							"
                  />
                  <polygon
                    id="Shape_3_"
                    fill="#1A237E"
                    fillOpacity="0.2"
                    points="329.5,-37.2 265,77.2 -19.3,77.2 -20.7,79.5 266.3,79.5 333.4,-39.5 							"
                  />
                  <polygon
                    id="Shape_4_"
                    fill="#1A237E"
                    fillOpacity="5.000000e-02"
                    points="49.6,-37.2 48.3,-39.5 -20.7,79.5 -18,79.5 							"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <defs>
              <filter
                id="Adobe_OpacityMaskFilter_1_"
                filterUnits="userSpaceOnUse"
                x="-20.7"
                y="-39.5"
                width="228.9"
                height="366.3"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="-20.7"
              y="-39.5"
              width="228.9"
              height="366.3"
              id="mask-5_2_"
            >
              <g filter="url(#Adobe_OpacityMaskFilter_1_)">
                <path
                  id="path-4_2_"
                  fill="#ffffff"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M258.6-39.5H121.1L-20.7,207.7l67.1,119h287l67.1-119L258.6-39.5z M115.3,207.7
							l74.5-128.7l74.5,128.7H115.3z"
                />
              </g>
            </mask>
            <g id="Group_2_" mask="url(#mask-5_2_)">
              <polygon
                id="Shape_5_"
                fill="#0F9D58"
                points="121.1,-39.5 -20.7,207.7 46.3,326.8 208.1,47.5 					"
              />
              <g id="Shape_6_">
                <polygon
                  fill="#263238"
                  fillOpacity="0.1"
                  points="121.1,-39.5 122.4,-32.8 187.2,79.1 46.4,322.1 -18.1,207.7 -20.7,207.7 46.3,326.8 189.9,79.1
													"
                />
                <polygon
                  fill="#ffffff"
                  fillOpacity="0.2"
                  points="-18.1,207.7 122.5,-37.2 121.1,-39.5 121.1,-39.5 -20.7,207.7 						"
                />
              </g>
            </g>
          </g>
          <g>
            <defs>
              <filter
                id="Adobe_OpacityMaskFilter_2_"
                filterUnits="userSpaceOnUse"
                x="121.1"
                y="-39.5"
                width="279.3"
                height="247.2"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="121.1"
              y="-39.5"
              width="279.3"
              height="247.2"
              id="mask-7_2_"
            >
              <g filter="url(#Adobe_OpacityMaskFilter_2_)">
                <path
                  id="path-6_2_"
                  fill="#ffffff"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M258.6-39.5H121.1L-20.7,207.7l67.1,119h287l67.1-119L258.6-39.5z M115.3,207.7
							l74.5-128.7l74.5,128.7H115.3z"
                />
              </g>
            </mask>
            <g id="Group_4_" mask="url(#mask-7_2_)">
              <g transform="translate(141.762664, 0.000000)">
                <polygon
                  id="Shape_7_"
                  fill="#FFCD40"
                  points="116.8,-39.5 -20.6,-39.5 122.6,207.7 258.7,207.7 						"
                />
                <g id="Group_3_">
                  <polygon
                    id="Shape_8_"
                    fill="#ffffff"
                    fillOpacity="0.2"
                    points="116.8,-39.5 -20.6,-39.5 -16.7,-37.2 115.5,-37.2 256,207.7 258.7,207.7
								"
                  />
                  <polygon
                    id="Shape_9_"
                    fill="#BF360C"
                    fillOpacity="0.1"
                    points="123.9,205.4 -16.7,-37.2 -16.7,-37.2 -20.6,-39.5 122.6,207.7 							"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
))
