import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 305.4 180.7" xmlns="http://www.w3.org/2000/svg" {...props}>
    {children}
    <path
      id="path4256"
      fill="#1F48AA"
      d="M99,179.6c-18.5-4.6-28.8-19.3-28.8-41.1c0-7,0.5-10.3,2.2-14.8c4.3-11,15.6-19.3,30.4-22.3
	c7.4-1.5,9.7-3.1,9.7-6.9c0-1.2,0.9-4.7,2-7.8c4.9-14.2,13.9-26,23.6-30.9c10.1-5.1,15.2-6.2,27.5-6.1c17.4,0.1,26,3.9,38.2,16.6
	l6.7,7l6-2.1c28.9-10,57.7,7,60.1,35.5l0.6,7.8l5.7,2c16.3,5.8,23.9,18.1,22.5,36c-0.9,11.8-6.4,21.1-15.1,25.8l-4.1,2.2l-91,0.2
	C125.1,180.8,102.8,180.5,99,179.6L99,179.6z"
    />
    <path
      id="path4248"
      fill="#1F48AA"
      d="M30.8,167C20,164.4,8.6,154.9,3.2,144c-3-6.2-3.2-7.1-3.2-18c0-10.4,0.3-12.1,2.7-17.2
	C7.8,97.8,17.6,90,29.8,86.9c2.6-0.7,5-1.7,5.4-2.3c0.4-0.6,0.8-4,0.9-7.5c0.9-21.8,15.1-41,35.1-47.1c10.8-3.3,24.3-2.5,36.1,2.2
	c3.7,1.5,3.3,1.8,11.2-8.5c4.7-6.1,14-13.7,21.7-17.5c8.3-4.2,16.9-6.1,27.3-6.1c28.9,0.1,53.7,18.1,62.9,45.7
	c2.9,8.8,2.8,11.3-0.7,11.3c-1.5,0-5.8,0.9-9.6,1.9l-6.9,1.8l-6.3-6.3c-17.7-17.7-46.6-21.5-71.2-9.4c-9.8,4.8-17.7,11.8-23.7,20.8
	c-4.2,6.5-9.7,18.5-9.7,21.5c0,2.1-1.7,3.2-9,5.6c-22.7,7.4-35.9,24.6-35.9,46.6c0,8,2.1,17.8,4.9,23.4c1.1,2.1,1.7,4.1,1.3,4.5
	C62.9,168.2,34.7,167.9,30.8,167L30.8,167z"
    />
  </svg>
))
