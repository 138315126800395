import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 40.1 21.6" xmlns="http://www.w3.org/2000/svg" {...props}>
    {children}
    <path
      fill="#285FCE"
      d="M39.7,19.2c0.5,0.7,0.4,1.6-0.2,2.1c-0.7,0.5-1.7,0.4-2.2-0.2l-3.5-4.5L30.4,21c-0.5,0.7-1.5,0.7-2.2,0.2
	s-0.8-1.4-0.3-2.1l4-5.2l-4-5.2C27.4,8,27.6,7,28.2,6.5c0.7-0.5,1.7-0.3,2.2,0.3l3.4,4.5L37.3,7c0.5-0.7,1.4-0.8,2.2-0.3
	c0.7,0.5,0.7,1.5,0.2,2.2L35.8,14L39.7,19.2z M21.5,18.6c-2.6,0-4.7-2-4.7-4.6c0-2.5,2.1-4.6,4.7-4.6s4.7,2.1,4.7,4.6
	C26.1,16.6,24,18.6,21.5,18.6z M7.7,18.6c-2.6,0-4.7-2-4.7-4.6c0-2.5,2.1-4.6,4.7-4.6s4.7,2.1,4.7,4.6C12.4,16.6,10.3,18.6,7.7,18.6
	z M21.5,6.4c-2.9,0-5.5,1.6-6.8,4c-1.3-2.4-3.9-4-6.9-4C6,6.4,4.4,7,3.1,7.9V1.5C3.1,0.7,2.4,0,1.6,0C0.7,0,0,0.7,0,1.5v12.6
	c0.1,4.2,3.5,7.5,7.7,7.5c3,0,5.6-1.7,6.9-4.1c1.3,2.4,3.9,4.1,6.8,4.1c4.3,0,7.8-3.4,7.8-7.7C29.3,9.8,25.8,6.4,21.5,6.4z"
    />
  </svg>
))
