import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import Jumbotron from "src/components/Jumbotron"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import * as styles from "./styles.module.scss"
import IntegrationCard from "src/components/IntegrationCard"
import ArrowLink from "src/components/ArrowLink"
import ZapierIntegrations from "src/images/zapier-integrations.png"
import ZapierMobile from "src/images/zapier-integrations-mobile.png"
import Zapier from "src/components/Icons/ZapierLogo"
import Link from "src/utils/Link"
import Logo from "src/images/structured-data-logo.png"
import GetStarted from "src/components/Banners/GetStarted"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath, getPage } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <Jumbotron title={texts.header} subtitle={texts.subheader} />
      <div className={`content-wrapper ${styles.integrations}`}>
        {texts.integrations.map((integration: any) => {
          return (
            <IntegrationCard
              key={integration.name}
              {...integration}
              linkText={texts.learnMore}
            />
          )
        })}
      </div>
      <div className={styles.zapier}>
        <div className={`content-wrapper ${styles.zapierContent}`}>
          <Zapier className={styles.zapierLogo} />
          <h2 className={styles.zapierTitle}>{texts.zapierTitle}</h2>
          <p className={styles.zapierdescription}>{texts.zapierDescription}</p>
          <ArrowLink
            text={texts.learnMore}
            to={getPage("integrations/zapier").url}
            className={styles.zapierLink}
          />
          <img src={ZapierMobile} className={styles.mobile} />
          <img src={ZapierIntegrations} className={styles.desktop} />
        </div>
      </div>
      <div className={`content-wrapper ${styles.app}`}>
        <h2 className={styles.appTitle}>{texts.submitTitle}</h2>
        <p className={styles.appDescription}>{texts.submitSubtitle}</p>
        <Link
          className={`button ${styles.sumbitButton}`}
          to={getPage("integrations/submit").url}
        >
          {texts.submitButtonText}
        </Link>
        <ArrowLink
          className={styles.documentationLink}
          text={texts.docuentationButtonText}
          to="https://developer.getflow.com/"
          target="_blank"
        />
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        integrations {
          name
          description
          url
        }
        learnMore
        zapierTitle
        zapierDescription
        submitTitle
        submitSubtitle
        submitButtonText
        docuentationButtonText
      }
    }
  }
`

export default Template
